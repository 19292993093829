.footerContainer {
  background-color: var(--bs-darkblue);
  /* text-align: center; */
  padding: 1rem 5rem;
  color: #ffffff;
}

.xhipmentInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  margin-bottom: 1rem;
}
  
img.xhip_white_logo {
  width: 160px;
  margin: 0 !important;
  margin-bottom: 10px !important;
}

p.xhip_label {
  font-size: 8px;
  margin-bottom: 0;
  font-weight: 500;
  color: #f7f7f7 !important;
  text-transform: uppercase !important;
  letter-spacing: 1px;
  max-width: 400px;
}

p.xhip_info {
    font-size: 14px;
    margin: 0 !important;
    max-width: 400px;
    font-weight: 300;
}

.footerContainer h5 {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 0;
  color: #c6c6c6 !important;
  text-transform: uppercase !important;
  letter-spacing: 1px;
}

ul.footerList {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 1px;
  color: #f7f7f7 !important;
}

ul.footerList li {
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 05px;
}

/* ul.footerList li::before {
  content: "-";
  position: relative;
  left: -5px;
  top: 0;
} */

ul.footerList li img {
  width: 14px;
  margin: 0;
}

ul.footerList li a {
  color: #f9f9f9 !important;
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
}

ul.footerList li a:hover {
  color: #ffffff !important;
  text-decoration: underline;
  font-weight: 450;
}

.isoCard {
  height: 140px;
}

@media screen and (max-width: 768px) {
  .footerContainer {
    padding: 1rem 1rem;
  }
}

address {
  font-style: normal;
  font-size: 14px;
  font-weight: 300;
  margin: 0;
  color: #f7f7f7 !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 10px !important;
  letter-spacing: 0.4px;
}

.xhip_info a {
  color: #f7f7f7 !important;
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
  cursor: pointer;
}

.xhip_info {
  gap: 0px !important;
}