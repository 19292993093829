.privacy_container{
    color: #777777;
}

.navbar-wrapper {
    background-color:var(--bs-darkblue);
    position: absolute;
    left: 0;
    right: 0;
    z-index: 9;
    padding: 1em;
    transition: all 0.3s ease-in;
    box-shadow: 5px 5px 5px rgba(125, 125, 125, 0.2);
}


.privacy_container .navbar-wrapper.active {
    position: fixed;
    background-color: var(--bs-white);
    animation: mymove 0.5s;
    overflow: hidden;
}

@keyframes mymove {
    0% {
        top: -300px;
        height: 0rem;
        padding: 0em;
    }

    30% {
        padding: 0em;
    }

    100% {
        top: 0;
        height: auto;
        padding: 1.25em;
    }
}

.privacy_container .logo-containers{
    padding: 0 2rem;
}
.privacy_container .logo-containers img {
    height: auto;
    max-width: 100%;

}
.privacy_container .privacy-body .privacy-heading h1{
    color: var(--bs-darkblue);
}
.privacy_container .privacy-body .privacy-heading h2{
    color: var(--bs-darkblue);
}
.privacy_container .privacy-body .privacy-heading h3{
    color: var(--bs-darkblue);
}
.privacy_container .privacy-body .privacy-heading h4{
    color: var(--bs-darkblue);
}
.privacy_container .privacy-body .privacy-heading h6{
    color: var(--bs-darkblue);
}
.privacy-body{
    padding-top: 11rem;
}

.privacy-heading h3{
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1.8rem;
}
.privacy-heading h2{
font-size: 2.25rem;
font-weight: 600;
}

.privacy-heading-text{

    display: flex;
    flex-direction: column;
    gap: 0.6rem;
}
.privacy-heading p{
    line-height: 1.65rem;
    font-weight: 600;
    font-size: 1rem;
}

.revisions p{
    font-weight: 600;
    word-spacing: 0.2rem;
    line-height: 1.65rem;

}
.visit h4,h6{
    font-weight: 600;

}
.privacy_container ul{
    padding-left: 0.99rem;
}
.privacy_container ul li{
    list-style: none;
}
.privacy_container ul li::before{
    content: "\2022"; 
    color: var(--bs-orange); 
    font-weight: bold; 
    font-size: 1.5rem;
    display: inline-block; 
    width: 1rem;
    margin-left: -1rem;
    position: relative;
    top: 0.25rem;
}
.privacy_container .endText{
    margin-bottom: 3rem;
}


@media screen and (max-width:991px) {
    .privacy_container .navbar-wrapper {
        padding: 0.8rem 0rem;
    }
    .privacy_container .logo-containers{
        padding: 0 0.5rem;
    }

}


@media screen and (max-width:550px) {
    .privacy_container .navbar-wrapper {
        padding: 0.9rem 1rem;
    }
    .privacy_container .logo-containers{
        padding: 0 0rem;
    }

    .privacy_container .navbar-containers {
        padding: 0 0rem;
    }
    .privacy_container .logo-containers img {
        height: auto;
        max-width: 50%;
        max-height: 50%;
    
    }
    .privacy_container .privacy-body{
        padding-top: 7.5rem;
    
    }
}