.term_container{
    color: #777777;
}

.navbar-wrapper {
    background-color:var(--bs-darkblue);
    position: absolute;
    left: 0;
    right: 0;
    z-index: 9;
    padding: 1em;
    transition: all 0.3s ease-in;
    box-shadow: 5px 5px 5px rgba(125, 125, 125, 0.2);
    

}
.term_container h2,h6{
    color: var(--bs-darkblue);
}

.term_container .navbar-wrapper.active {
    position: fixed;
    background-color: var(--bs-white);
    animation: mymove 0.5s;
    overflow: hidden;
}

@keyframes mymove {
    0% {
        top: -300px;
        height: 0rem;
        padding: 0em;
    }

    30% {
        padding: 0em;
    }

    100% {
        top: 0;
        height: auto;
        padding: 1.25em;
    }
}

.term_container .logo-containers{
    padding: 0 2rem;
}
.term_container .logo-containers img {
    height: auto;
    max-width: 100%;

}

.term_container .terms_body{
    padding-top: 11rem;

}

.term_container .term-heading h2{
    color: var(--bs-darkblue);
    font-size:2.2rem;
    font-weight: 600;
    word-spacing: 0.2rem;
}
.term_container .term-heading .hp1{
    font-weight: 600;
    margin-top: 1.8rem;
    word-spacing: 0.11rem;
    color: #777777;
}
.term_container .term-heading .hp2{
font-weight: 600;
}
.term_container .term-heading p{
    font-weight: 500;
    margin-top: 1.5rem;
    letter-spacing: 0.02rem;
    word-spacing: 0.11rem;
    font-size: 0.97rem;
    line-height: 1.5rem;
}

.term_container .points h6{
    color: var(--bs-darkblue);
    font-weight: 600;
    margin-top: 1.6rem;
    font-size: 1rem;
}
.term_container .points p{
    font-weight: 500;
    font-size: 0.98rem;
    word-spacing: 0.12rem;
    line-height: 1.5rem;
}
.term_container .General h6{
    margin-bottom: -1rem;
}
.term_container .General p{
    margin-top: 1.5rem;
}

.term_container ul li{
    list-style: none;
    font-weight: 500;
    
}
.term_container li{
    font-weight: 500;
    line-height: 1.4rem;
}

.term_container ul li::before{
    content: "\2022"; 
    color: var(--bs-orange); 
    font-weight: bold; 
    font-size: 1.5rem;
    display: inline-block; 
    width: 1rem;
    margin-left: -1rem;
    position: relative;
    top: 0.25rem;
}

.term_container .GUARANTEES p:nth-last-child(1){
    margin-top: 1.5rem;
    font-size: 0.95rem;

}
.term_container .DISCLAIMER p{
    font-weight: 500;
    font-size: 0.98rem;
}
.term_container .DISCLAIMER p:nth-last-child(1){
    margin-top: 1.4rem;
}

.term_container .NOTICES p{
    font-size: 1rem;
}

.term_container .MISCELLANEOUS p{
margin-top: 1.5rem;
}

.term_container.MISCELLANEOUS .Misfirst{
    margin-top : 0.2rem;
}

.term_container .Quotation h2{
    margin-top: 2.5rem;
    font-weight: 600;
    font-size: 2.2rem;
}
.term_container .Quotation dt{
    color: var(--bs-darkblue);
}
.term_container .romanPoints li{
    list-style-type: lower-roman;
}
.term_container .romanPoints li::before{
    content: '';
}
.term_container .alphaList li{
    list-style-type: upper-alpha;
}
.term_container .alphaList li::before{
    content: '';
}
.term_container .LICENSING h2{
    margin-top: 3.8rem;
    margin-bottom: 3.5rem;
}

.term_container .licensing-text{
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
}

.term_container .numberList{
    counter-reset: item;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
.term_container .numberList li{
    display: block;
}
.term_container .numberList li::before{
    content: counters(item, ".") "";
    counter-increment: item;
}
.term_container .termsAndConditions{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.term_container .endTextsym{
    font-size: 1.3rem;
    letter-spacing: 0.1rem;
}
.term_container span{
    font-weight: 700;
    font-size: 1rem;
}

.term_container a{
    color: var(--bs-orange);
    font-weight: 500;
}

.licensingP4{
    word-spacing: 0.31rem;
    font-weight: 600;
}

.end{
    margin-bottom: 3rem;
}


.term_container ul{
    padding-left: 0.8rem;
}

.term_container ol{
    padding-left: 0rem;
}

.term_container .romanPoints,.alphaList{
    padding-left: 1rem !important;
    
}
.table thead tr th{
    font-weight: 700;
    color: #777777;
}

.table tbody tr td{
    color: #777777 !important;
    font-size: 16px;
}

.oceanTable tbody tr td:nth-child(1){
    text-wrap: nowrap;
    }
.table tbody tr td:nth-child(2){
text-wrap: nowrap;
}


@media screen and (max-width:991px) {
    .term_container .navbar-wrapper {
        padding: 0.8rem 0rem;
    }
    .term_container .logo-containers{
        padding: 0 0.5rem;
    }

}


@media screen and (max-width:550px) {
    .term_container .navbar-wrapper {
        padding: 0.9rem 1rem;
    }
    .term_container .logo-containers{
        padding: 0 0rem;
    }

    .term_container .navbar-containers {
        padding: 0 0rem;
    }
    .term_container .logo-containers img {
        height: auto;
        max-width: 50%;
        max-height: 50%;
    
    }
    .term_container .terms_body{
        padding-top: 8rem;
    
    }
}