@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&family=Roboto:wght@400;500;700&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    text-decoration: none !important;
    font-family: var(--bs-font-archivo);
}

:root {
    --bs-orange: #FF4F00;
    --bs-white: #fff;
    --bs-darkblue: #05103B;
    --bs-bgcolor: rgb(249, 249, 249);
    --bs-lightgray: #bcbcbc;
    --bs-gray: #8f8e8e;
    --bs-bgblue: #1B2332;
    --bs-blue: #091649;
    --bs-creamy: #FFF0D0;
    --bs-hoverorange: #ef2917;
    --bs-bgcolor2: #F5F5F5;
    --bs-font-dmsans: 'DM Sans', sans-seri;
    --bs-font-roboto: 'Roboto', sans-serif;
    --bs-font-archivo: 'Archivo', sans-serif;
}

::selection {
    color: var(--bs-white);
    background: var(--bs-orange);
}
