/* Custom Scrollbar */
body::-webkit-scrollbar {
    width: 0.3rem;
}

body::-webkit-scrollbar-track {
    background: var(--bs-bgblue);
}

body::-webkit-scrollbar-thumb {
    background: #ff4f00;
    border-radius: 0.5rem;
}