.sidebar_container {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.207);
    z-index: 18;
}

.close {
    position: fixed;
    width: 12rem;
    height: 100vh;
    top: 0;
    right: -200rem;
    z-index: 15;
    animation: sidebarContStart 1s ease-in-out 0s;
}

@keyframes sidebarContStart {
    from {
        right: 0rem
    }

    to {
        right: -20rem;
    }
}

.sidebar {
    position: absolute;
    width: 12rem;
    background-color: white;
    color: black;
    height: 100vh;
    top: 0;
    padding: 3rem 1.5rem;
    z-index: 15;

}

.sidebar.close {
    right: -20rem;
    animation: sidebarStart 2.1s ease-in-out 0s;
}

@keyframes sidebarStart {
    from {
        right: 0rem
    }

    to {
        right: -20rem;
    }
}

.sideactive {
    right: 0rem;
    animation: sidebarEnd 1s ease 0s;
}

@keyframes sidebarEnd {
    from {
        right: -20rem
    }

    to {
        right: 0rem;
    }
}

.cross {
    text-align: right;
    margin-bottom: 1.5rem;

}

.cross-icon {
    font-weight: var(--bs-font-archivo);
    font-size: 1.3rem;
    font-size: 800;
    transition: all 0.35s ease 0s
}

.cross-icon:hover {
    transform: rotate(90deg);
}

.navbar {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1.2rem;
    font-size: 1.05rem;
    font-family: var(--bs-font-archivo);
}

.navbar li {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
}

.navbar a {
    color: black;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: max-content !important;
}

.navbar a:hover {
    color: var(--bs-orange);
}