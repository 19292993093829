.wrapper {
    width: 100%;
    height: auto;
}

.main-content {
    width: 100%;
    height: auto;
    position: relative;
}

.bg-image-container {
    position: absolute;
    width: 100%;
    height: 100%;
}

.bg-image-container img {
    object-fit: cover;
    width: 100%;
    height: 100%;

}

.img-overlay {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(4, 14, 51, 0.682)
}

.header-content {
    height: auto;
    position: relative;
    padding-top: 14rem;
    padding-bottom: 5rem;
    color: var(--bs-white);
    text-align: center;
    font-family: var(--bs-font-archivo);
    overflow: hidden;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

}


.header-content .wrap-one h2 {
    position: relative;
    font-size: 3.2rem;
    width: 79%;
    max-width: 1100px;
    margin: auto;
    letter-spacing: 0.0rem;
    margin-bottom: 1.8rem;
    animation: mytextH2 1.5s linear;
    font-weight: 700;
}

@keyframes mytextH2 {
    0% {
        opacity: 0.2;
        bottom: -200px;
    }

    100% {
        opacity: 1;
        bottom: 0;
    }


}

.header-content .wrap-two p {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0 auto;
    animation: mytextp 1.8s linear;
    position: relative;
}

.header-content .wrap-two .small-heading {
    font-size: 1.1rem;
}

@keyframes mytextp {
    0% {
        bottom: -300px;
        visibility: hidden;
    }

    100% {
        bottom: 0;
        visibility: visible;
    }
}

.wrap-two a {
    color: var(--bs-orange);
}

.amazon_logo {
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    flex-wrap: wrap;
}

.LogoContainer {
    animation: logoanimate 1.9s linear;
    position: relative;
    bottom: 0;
}

.LogoContainer img {
    height: 50px;
    object-fit: cover;
    border-radius: 0.5rem;
}

@keyframes logoanimate {
    0% {
        bottom: -350px;
        visibility: hidden;
    }

    100% {
        bottom: 0;
        visibility: visible;
    }

}

@media screen and (max-width: 765px) {
    .wrapper {
        width: 100%;
        min-height: auto;
        height: auto;
    }

    .main-content {

        min-height: auto;
        height: auto;

    }

    .header-content {
        min-height: auto;
        padding-top: 10rem;
    }

    .header-content .wrap-one h2 {
        font-size: 2.1rem;
        margin-bottom: 1.2rem;
    }

    .header-content .wrap-two p {
        font-size: 1.1rem;
        margin: 0 auto;
    }

    .LogoContainer {

        animation: logoanimate 1.9s linear;
        position: relative;
        bottom: 0;
        width: 200px;
        height: 70px;

    }

    .LogoContainer.ffi {
        width: auto;
        height: 70px;
    }

}

.iso_logo {
  position: absolute;
  width: 100px !important;
  height: 100px !important;
  right: 80px;
  bottom: -40px;
}

.iso_logo img {
  width: 100px !important;
  height: 100px !important;
  transform: rotate(10deg);
}

.footer_container2 {
    width: 100%;
    height: auto;
    font-size: 12px;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width:550px) {

    .header-content .wrap-one h2 {
        font-size: 2.2rem;
    }

    .header-content .wrap-two p {
        font-size: 1rem;
        margin: 0 auto;
    }

    .LogoContainer {

        animation: logoanimate 1.9s linear;
        position: relative;
        bottom: 0;
        width: 160px;
        height: 50px;

    }

    .iso_logo {
      position: absolute;
      width: 100px !important;
      height: 100px !important;
      right: 47%;
      transform: translate(50%, 0);
      bottom: -58px;
    }

    .iso_logo img {
        width: 80px !important;
        height: 80px !important;
        transform: rotate(0deg);
    }

    .wrapper {
      margin-bottom: 40px;
    }
}
