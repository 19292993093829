.navbarhome-wrapper {
    background-color: rgba(0, 0, 0, 0.455) !important;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 300;
    padding: 1.25rem;
    transition: all 0.3s ease-in;

}

.navbar-wrapper.active {
    position: fixed;
    background-color: var(--bs-white);
    animation: mymove 0.5s;
    overflow: hidden;
    z-index: 300;
}


@keyframes mymove {
    0% {
        top: -300px;
        height: 0rem;
        padding: 0em;
    }

    30% {
        padding: 0em;
    }

    100% {
        top: 0;
        height: auto;
        padding: 1.25em;
    }
}

.navbarhome-containers {
    padding: 0 3.5rem;
    display: flex;
    align-items: center;
    justify-content: space-around;

}


.logo-container img {
    height: auto;
    max-width: 100%;
}

.navigationhome-anchors {
    color: #fff ;
    font-size: 1.05rem;
    font-family: var(--bs-font-archivo);
    font-weight: 500;
    transition: color 0.4s ease;
}

.navigationhome-anchors.mactive {
    color: rgba(0, 0, 0, 0.94);
}

.navigationhome-anchors:hover {
    color: var(--bs-hoverorange);
}


.header-action-area {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.2rem;
}

.btn-theme {
    display: inline-block;
    width: 7.4rem;
    padding: 0.9rem 0;
    border-radius: 0.2rem;
    background-color: var(--bs-orange);
    color: var(--bs-white);
    font-weight: 500;
    font-family: var(--bs-font-archivo);
    font-size: 1.05rem;
    transition: all 0.4s ease;
    border: none;
    text-align: center;
}

.btn-theme:hover {
    color: var(--bs-orange);
    background-color: var(--bs-white);
}

.btn-theme.active:hover {
    color: var(--bs-white);
    background-color: var(--bs-darkblue);
}



.btn-menu:hover {
    color: var(--bs-orange);
    background-color: var(--bs-white);
}

.btn-menu.active:hover {
    color: var(--bs-white);
    background-color: var(--bs-darkblue);
}

.menubar {
    font-size: 2rem;
}




@media screen and (max-width:991px) {
    .navbarhome-wrapper {
        padding: 1.1em 1rem;
    }

    .btn-theme {
        width: 5rem;
        padding: 0.75rem;
        font-weight: 500;
        font-size: 1rem;
    }

    .navbarhome-containers {
        padding: 0 0rem;
        justify-content: space-between;
    }

    .btn-menu {
        background-color: var(--bs-orange);
        width: 3.2rem;
        height: 3rem;
        border-radius: 0.2rem;
        padding: 0.1rem 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--bs-white);
        transition: all 0.4s ease;
        border: none;
    }

    .header-action-area {
        gap: 0.8rem;
    }
}


@media screen and (max-width:550px) {
    .navbarhome-wrapper {
        padding: 1em 0.1rem;
    }

    .logo-container img {
        height: auto;
        max-width: 70%;
    }

    .btn-theme {
        width: 4rem;
        padding: 0.5rem;
        font-weight: 500;
        font-size: 0.9rem;
    }

    .navbarhome-containers {
        padding: 0 0rem;
    }

    .btn-menu {
        background-color: var(--bs-orange);
        width: 2.6rem;
        height: 2.3rem;
    }

    .menubar {
        font-size: 1.5rem;
        font-weight: bolder;
    }

    .header-action-area {
        gap: 0.4rem;
    }
}