.faqConatiner {
    padding: 7rem 15rem 5rem 15rem;
    background-color: var(--bs-darkblue);
    font-family: var(--bs-font-archivo);

}

.faqHeader {
    text-align: center;
}

.faqHeader h2 {
    padding-bottom: 0rem;
    font-size: 1.7rem;
    letter-spacing: 0.07rem;
    color: var(--bs-white);
    font-weight: 500;
}

.faqHeader span {
    display: inline-block;
    width: 13rem;
    height: 0.07rem;
    background-color: var(--bs-lightgray);
    position: relative;
}

.faqHeader span::after {
    position: absolute;
    content: '';
    width: 3rem;
    height: 0.15rem;
    background-color: var(--bs-orange);
    inset: 0;
    left: 50%;
    top: 0.1rem;
    transform: translate(-50%, -50%);
}

.faqsections {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.9rem;
    overflow: hidden;
}

.faqsection {
    overflow: hidden;
    position: relative;
    z-index: 90;
}

.faqsection .qText {
    color: var(--bs-black);
    background-color: var(--bs-white);
    padding: 1rem 2rem;
    border-radius: 1.2rem;
    position: relative;
    font-size: 1.06rem;
    cursor: pointer;
    position: relative;
    z-index: 90;
}

.faqsection .qText::after {
    content: "❯";
    width: 1em;
    height: 1em;
    text-align: center;
    font-weight: 800;
    color: rgb(255, 79, 1);
    transition: all 0.35s ease 0s;
    position: absolute;
    right: 1rem;
}

.faqsection .qText.rotate::after {
    transform: rotate(90deg);
}

.databind {
    position: relative;
    color: var(--bs-white);
    padding: 0 1.8rem;
    transition: all 35s ease-in-out;
    background: transparent;
    text-align: left;
    overflow: hidden;
    -webkit-transition: 0.3s;
    animation: faqs 0.5s ease;
    overflow: hidden;
    z-index: 0;

}

@keyframes faqs {
    from {
        top: -200px;
    }

    to {
        top: 0;
    }
}

.databind p {
    /* word-spacing: 0.06rem; */
    font-size: 0.98rem;
    transition: all 3.5s ease-in-out;

}


.databind p a {
    color: var(--bs-orange);
}




@media screen and (max-width: 1080px) {
    .faqConatiner {
        padding: 4rem 10rem 4rem 10rem;
    }

    .faqsection .qText.sm1::after {
        content: "❯";
        width: 1em;
        height: 1em;
        text-align: center;
        font-weight: 800;
        color: rgb(255, 79, 1);
        transition: all 0.35s ease 0s;
        position: absolute;
        right: 0.3rem;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .faqsection .qText.sm1.rotate::after {
        transform: rotate(90deg);
    }
}

@media screen and (max-width: 765px) {
    .faqConatiner {
        padding: 4rem 5rem 4rem 5rem;
    }

}


@media screen and (max-width:550px) {
    .faqConatiner {
        padding: 4rem 2rem 4rem 2rem;
    }

    .faqHeader h2 {
        font-size: 1.5rem;
    }

}