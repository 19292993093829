.serviceComponent {
    padding: 7rem 3rem 3rem 3rem;
    text-align: center;
    background-color: var(--bs-bgcolor2);
    font-family: var(--bs-font-archivo);
    overflow: hidden;
}

.serviceHeader {
    padding: 0 4rem;
}

.serviceHeader h1 {
    font-size: 2.8rem;
    color: var(--bs-darkblue);
    font-weight: 600;
}

.serviceHeader span {
    display: inline-block;
    width: 13rem;
    height: 0.07rem;
    background-color: var(--bs-lightgray);
    position: relative;
}

.serviceHeader span::after {
    position: absolute;
    content: '';
    width: 3rem;
    height: 0.15rem;
    background-color: var(--bs-orange);
    inset: 0;
    left: 50%;
    top: 0.1rem;
    transform: translate(-50%, -50%);
}

.serviceSections {
    /* display: flex;
    justify-content: space-evenly;
    gap: 1.7rem;
    flex-wrap: wrap; */
    padding: 3rem 4rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.7rem;
}

.ServiceSection:nth-child(1) {
    grid-column: 2 / 3;
}

.ServiceSection:nth-child(2) {
    grid-column: 3/ 4;
}

.ServiceSection:nth-child(3) {
    grid-column: 1/2;
}

.ServiceSection {
    background-color: var(--bs-white);
    padding: 0.9rem 1.3rem 0.5rem;
    border-radius: 0.4rem;
    box-shadow: 0px 0px 5px rgba(222, 222, 222, 0.436);
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
}



.ServiceSection img {
    width: 2rem;
    height: 2rem;
    margin: 0 auto;
}


.ServiceSection .icon-text h4::before {
    position: absolute;
    content: '';
    width: 4rem;
    height: 0.16rem;
    background-color: var(--bs-lightgray);
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: -10px;
}

.iconContainer .icons {
    color: #ff4f00 !important;
}

@media screen and (min-width: 1475px) {
    .serviceComponent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

}

@media screen and (min-width: 1475px) {
    .serviceHeader {
        padding: 0 3rem;
    }

    .serviceSections {

        padding: 2rem 6rem;
    }



}

@media screen and (max-width: 1200px) {
    .serviceSections {
        grid-template-columns: repeat(3, 1fr);
    }

    .ServiceSection:nth-child(1) {
        grid-column: 1/ 2;
    }

    .ServiceSection:nth-child(2) {
        grid-column: 2/ 3;
    }

    .ServiceSection:nth-child(3) {
        grid-column: 3/4;
    }

    .ServiceSection:nth-child(9) {
        grid-column: 3/4;
    }

    .ServiceSection:nth-child(10) {
        grid-column: 2/3;
    }
}

@media screen and (max-width: 850px) {
    .serviceHeader {
        padding: 0 2rem;
    }



    .serviceHeader h1 {
        font-size: 1.98rem;
    }

    .serviceSections {
        grid-template-columns: repeat(2, 1fr);
        padding: 2rem 2rem;
    }

    .ServiceSection:nth-child(1) {
        grid-column: 1/ 2;
    }

    .ServiceSection:nth-child(2) {
        grid-column: 2/ 3;
    }

    .ServiceSection:nth-child(3) {
        grid-column: 1/2;
    }

    .ServiceSection:nth-child(9) {
        grid-column: 1/2;
    }

    .ServiceSection:nth-child(10) {
        grid-column: 2/3;
    }



}


@media screen and (max-width:570px) {
    .serviceHeader {
        padding: 0 1rem;
    }

    .serviceComponent {
        padding: 2rem 1rem 3rem 1rem;
    }

    .serviceHeader h1 {
        font-size: 1.6rem;
    }

    .serviceSections {
        grid-template-columns: repeat(1, 1fr);
        padding: 2rem 1.5rem;
    }

    .ServiceSection:nth-child(1) {
        grid-column: 1/ 2;
    }

    .ServiceSection:nth-child(2) {
        grid-column: 1/2;
    }

    .ServiceSection:nth-child(9) {
        grid-column: 1/2;
    }

    .ServiceSection:nth-child(10) {
        grid-column: 1/2;
    }


}