.talkContainer {
    padding: 7rem 10rem 3rem 10rem;
    text-align: center;
    background-color: var(2);
    font-family: var(--bs-font-archivo);
    overflow: hidden;
}

.talkContainer h2 {
    font-size: 2.25rem;
    color: var(--bs-darkblue);
    font-weight: 600;
    word-spacing: 0.2rem;
    letter-spacing: 0.02rem;
    margin-bottom: -0.3rem;
}

.talkContainer span {
    display: inline-block;
    width: 13rem;
    height: 0.07rem;
    background-color: var(--bs-lightgray);
    position: relative;

}

.talkContainer span::after {
    position: absolute;
    content: '';
    width: 3rem;
    height: 0.15rem;
    background-color: var(--bs-orange);
    inset: 0;
    left: 50%;
    top: 0.1rem;
    transform: translate(-50%, -50%);
}

.talkFormConatiner form .inputs{
    margin-top: 3rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.6rem;
    margin-bottom: 1.8rem;
}
.talkFormConatiner form .inputs .btnConatiner{
    margin-top: 3rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1.6rem;
    margin-bottom: 1.8rem;
}
.radios_container{
    grid-column-start: 1;
    grid-column-end: 3;
    text-align: left;
    display: flex;
    gap: 2rem;
    align-items: center;
    flex-wrap: wrap;
    font-size: 1.05rem !important;
    color: rgb(103, 103, 103);

}
.radiosButtons{
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
}
input[type='radio'] {
    accent-color: var(--bs-orange) ;
   
}


.shipToUs{
    margin-top: -0.5rem;
}
.radios{
    margin-top: -13px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
    align-items: center !important;
    justify-content: space-around;
}

/* .textarea {
    grid-column-start: 1;
    grid-column-end: 3;
    background-color: #F5F5F5;
    border: none;
    padding: 0.8rem 1rem;
    resize: none;
    border-radius: 0.1rem;
}

.textarea:focus {
    outline: none !important;
    border: 3px solid #F5F5F5;
    background-color: transparent;
} */
.InputurlLink{
    margin-top: -0.8rem;
    background-color: #F5F5F5;
    border: none;
    padding: 0.6rem 1rem;
    width: 20rem;
}
.input {
    padding: 0.8rem 1rem;
    border: none;
    background-color: #F5F5F5;
    border-radius: 0.1rem;
}

.input:focus {
    outline: none !important;
    border: 3px solid #F5F5F5;
    background-color: transparent;
}

.btnContainer {
    display: flex;
    justify-content: center;
    align-items: center;

}

.Submitbtn {

     border: none;
    background-color: var(--bs-orange);
    color: var(--bs-white);
    padding: 0.9rem 2.3rem;
    border-radius: 0.1rem;
    box-shadow: 1.5px 1.5px #de4701;

}

.Submitbtn:hover {
    background-color: var(--bs-darkblue);
    box-shadow: 1.5px 1.5px var(--bs-darkblue);
    transition: all 0.5s ease;
}
.inputFiled{
    padding: 0;
    margin: 0;
}
.inputFiled p{
    display: inline-block;
    padding: 0;
    margin: 0;
}

.aesterisk{
    color: red;
    font-size: 1rem;
    font-weight: 600;
    margin-left: 0.2rem;
}


@media screen and (max-width: 1080px) {
    .talkContainer {
        padding: 3rem 6rem 3rem 6rem;
    }
}

@media screen and (max-width: 845px) {
    .talkContainer {
        padding: 3rem 4rem 3rem 4rem;
    }

    .talkFormConatiner form .inputs {
        grid-template-columns: 1fr;
    }

    .radios_container {
        grid-column-start: 1;
        grid-column-end: 2;
        gap: 0.5rem;
    }
    .radiosButtons{
        gap: 1rem;
    }
    .radios{
        gap: 0.2rem;
    }
}


@media screen and (max-width:550px) {
    .talkContainer {
        padding: 3rem 2rem 3rem 2rem;
    }
}