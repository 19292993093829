.Testimonials-conatainer {
    padding: 5rem 5rem 0rem 5rem !important;
    text-align: center;
    background-color: var(--bs-bgcolor);
    font-family: var(--bs-font-archivo);
}

.Testimonial_heading h2 {
    font-size: 2.1rem;
    letter-spacing: 0.07rem;
    color: var(--bs-darkblue);
    font-weight: 600;
}

.section-title-line {
    display: inline-block;
    width: 13rem;
    height: 0.07rem;
    background-color: var(--bs-lightgray);
    position: relative;
}

.section-title-line::after {
    position: absolute;
    content: '';
    width: 3rem;
    height: 0.15rem;
    background-color: var(--bs-orange);
    inset: 0;
    left: 50%;
    top: 0.1rem;
    transform: translate(-50%, -50%);
}

.TestimonialSection_container {
    display: flex;
    justify-content: space-evenly;
    gap: 2.5rem;
    padding: 4.5rem 5rem;
    /* flex-wrap: wrap; */
}

.sections.testimonial-section {
    position: relative;
    min-width: 22rem;
    max-width: 22rem;
    background-color: var(--bs-white);
    padding: 1rem 1.5rem;
    border-radius: 0.4rem;
    box-shadow: 0px 0px 5px rgba(222, 222, 222, 0.436);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5rem;
    overflow: hidden;
}


.handsHelpingtestimonial {
    font-size: 2.8rem;
}

.testimonial-text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.testimonial-text h4 {
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: normal;
    color: var(--bs-darkblue);
    position: relative;
}

.quotes-icon {
    position: absolute;
    top: -20px;
    left: 0;
    width: 7rem;
    height: 7rem;
    z-index: -2;
    opacity: 0.05;

}

.testimonial-text h4::after {
    position: absolute;
    content: '';
    width: 4rem;
    height: 0.15rem;
    background-color: var(--bs-orange);
    left: 0;
    bottom: -10px;
}

.testimonial-text p {
    color: var(--bs-gray);
    font-size: 0.98rem;
}

@keyframes scroll {
    from {
        width: 0;
        height: 0;
        opacity: 0;
    }

    to {
        width: 40;
        height: 40;
        opacity: 1;
    }
}


@media screen and (max-width: 1452px) {
    .Testimonials-conatainer {
        padding: 5rem 3rem 4rem 3rem;
    }

    .sections {
        min-width: 12rem;
        max-width: 25rem;
        width: 17rem;
        padding: 1rem 1.3rem;
    }



}

@media screen and (max-width: 1200px) {
    .sections.testimonial-section {
        min-width: 18rem;
        max-width: 18rem;
        padding: 0.8rem 1.2rem;
        gap: 0.5rem;

    }


    .handsHelpingtestimonial {
        font-size: 2.4rem;
    }

    .testimonial-text {
        gap: 0.8rem;
    }

    .testimonial-text h4 {
        font-size: 1rem;
        font-weight: 600;
    }

    .quotes-icon {
        width: 6rem;
        height: 6rem;
    }

    .testimonial-text h4::after {
        width: 3.5rem;
        height: 0.15rem;
    }

    .testimonial-text p {
        font-size: 0.9rem;
    }

}

@media screen and (max-width: 1080px) {
    .TestimonialSection_container {
  
        gap: 1.5rem;
    }
    .Testimonials-conatainer {
        padding: 6rem 4rem 4rem 4rem;

    }

    .sections {
        min-width: 22rem;
        max-width: 35rem;
        width: 35rem;
        padding: 1rem 1.5rem;
    }

    .handsHelpingtestimonial {
        font-size: 2.7rem;
    }

    .sections.testimonial-section {
        min-width: 16.2rem;
        max-width: 16.2rem;
        padding: 0.6rem 1rem;
        gap: 0.5rem;

    }

    .handsHelpingtestimonial {
        font-size: 2rem;
    }

    .testimonial-text {
        display: flex;
        flex-direction: column;
        gap: 0.6rem;
    }

    .testimonial-text h4 {
        font-size: 0.95rem;
        font-weight: 500;
    }

    .quotes-icon {
        width: 5rem;
        height: 5rem;
    }

    .testimonial-text h4::after {
        width: 3rem;

    }

    .testimonial-text p {
        font-size: 0.85rem;
    }

}

@media screen and (max-width: 850px) {
    .TestimonialSection_container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .sections.testimonial-section {
        min-width: 20rem;
        max-width: 22rem;
        padding: 0.8rem 1.2rem;
        gap: 0.5rem;
    }


    .handsHelpingtestimonial {
        font-size: 2.4rem;
    }

    .testimonial-text {
        gap: 0.8rem;
    }

    .testimonial-text h4 {
        font-size: 1.3rem;
        font-weight: 600;
    }

    .quotes-icon {
        width: 6rem;
        height: 6rem;
    }

    .testimonial-text h4::after {
        width: 3.5rem;
    }

    .testimonial-text p {
        font-size: 0.9rem;
    }

}

@media screen and (max-width: 765px) {
    .Testimonials-conatainer {
        padding: 3rem 4rem 4rem 4rem;
    }

    .Testimonial_heading h2 {
        font-size: 1.98rem;
    }

    .TestimonialSection_container {
        gap: 2rem;
        padding: 3rem 1.5rem;
    }

    .sections {
        min-width: 22rem;
        max-width: 30rem;
        padding: 1rem 1.5rem;
    }


    .handsHelpingtestimonial {
        font-size: 2.7rem;
    }

}


@media screen and (max-width:550px) {
    .Testimonials-conatainer {
        padding: 0rem 2rem 3rem 2rem;
    }

    .sections {
        min-width: 18rem;
        max-width: 18rem;
        padding: 1rem 1.5rem;
    }

    .Testimonial_heading h2 {
        font-size: 1.7rem;
    }

    .TestimonialSection_container {
        gap: 2rem;
        padding: 2rem 1.5rem;
    }

}