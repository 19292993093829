.License-conatainer {
  padding: 1.5rem 1rem;
}

.License-conatainer h2 {
  width: 100%;
  text-align: center;
  margin: auto;
  font-size: 1.3rem;
  font-weight: 550;
  color: #091649;
}

.license_cards {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  margin: 20px auto 0;
  align-items: stretch;
}

.license_cards a {
  text-decoration: none !important;
  color: #000000;
  flex-grow: 1;
}

.license_card {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: start;
  border: 1px solid #09164940;
  box-shadow: 0 0 30px -10px #09164910;
  padding: 5px 10px;
  border-radius: 5px;
  flex-grow: 1;
  height: 100%;
}

.license_card img {
  height: 45px;
}

.license_card p {
  margin: 0;
  max-width: 280px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

@media screen and (max-width: 765px) {
  .license_cards {
    flex-direction: column;
    gap: 20px;
  }

  .license_card {
    flex-direction: column;
    gap: 10px;
  }

  .license_card p {
    max-width: 80%;
    text-align: center;
  }
}
