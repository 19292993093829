.AboutUs-conatainer {
    padding: 8rem 5rem 0rem 5rem !important;
    text-align: center;
    background-color: #fefefe;
    font-family: var(--bs-font-archivo);
}

.Aboutus_heading h2 {
    font-size: 2.1rem;
    letter-spacing: 0.07rem;
    color: var(--bs-darkblue);
    font-weight: 600;
}

.section-title-line {
    display: inline-block;
    width: 13rem;
    height: 0.07rem;
    background-color: var(--bs-lightgray);
    position: relative;
}

.section-title-line::after {
    position: absolute;
    content: '';
    width: 3rem;
    height: 0.15rem;
    background-color: var(--bs-orange);
    inset: 0;
    left: 50%;
    top: 0.1rem;
    transform: translate(-50%, -50%);
}

.AboutusSection_container {
    display: flex;
    justify-content: space-evenly;
    gap: 2.5rem;
    padding: 4.5rem 5rem;
    flex-wrap: wrap;
}

.sections {
    min-width: 22rem;
    max-width: 22rem;
    background-color: var(--bs-white);
    padding: 1rem 1.5rem;
    border-radius: 0.4rem;
    box-shadow: 0px 0px 20px -5px rgba(222, 222, 222, 0.436) !important;
    border: 1px solid #09164910;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.icons {
    font-size: 2.5rem;
    color: var(--bs-orange);
}

.handsHelpingicon {
    font-size: 2.8rem;
}

.icon-text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.icon-text h4 {
    font-size: 1.1rem;
    letter-spacing: 0.08rem;
    color: var(--bs-darkblue);
    position: relative;
}

.AboutUs-conatainer .icon-text h4::after {
    position: absolute;
    content: '';
    width: 4rem;
    height: 0.15rem;
    background-color: var(--bs-lightgray);
    inset: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 33px;
}

.icon-text p {
    color: var(--bs-gray);
    font-size: 0.98rem;
}


.scrollContainer {
    position: fixed;
    bottom: 3rem;
    width: 40px;
    height: 40px;
    right: 3rem;
    background-color: rgb(0, 0, 0);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--bs-white);
    font-weight: bolder;
    font-size: 1.2rem;
    box-shadow: 0px 0px 7px var(--bs-darkblue);
    z-index: 8;
    animation: scroll 0.35s linear;
}

@keyframes scroll {
    from {
        width: 0;
        height: 0;
        opacity: 0;
    }

    to {
        width: 40;
        height: 40;
        opacity: 1;
    }
}

.scrollContainer a {
    color: var(--bs-white);
}

@media screen and (max-width: 1452px) {
    .AboutUs-conatainer {
        padding: 5rem 2rem 4rem 2rem;
    }

    .sections {
        min-width: 12rem;
        max-width: 25rem;
        width: 17rem;
        padding: 1rem 1.3rem;
    }



}
@media screen and (max-width: 1186px) {
    .AboutusSection_container{
        flex-wrap: nowrap;
    }
}

@media screen and (max-width: 1080px) {
    .AboutUs-conatainer {
        padding: 6rem 3rem 4rem 3rem;
    }
    .AboutusSection_container{
        flex-wrap: wrap;
    }
    /* .sections {
        min-width: 20rem;
        max-width: 35rem;
        width: 35rem;
        padding: 1rem 1.5rem;
    } */

    .handsHelpingicon {
        font-size: 2.7rem;
    }

}

@media screen and (max-width: 765px) {
    .AboutUs-conatainer {
        padding: 3rem 4rem 4rem 4rem;
    }

    .Aboutus_heading h2 {
        font-size: 1.98rem;
    }

    .AboutusSection_container {
        gap: 2rem;
        padding: 3rem 1.5rem;
    }

    .sections {
        min-width: 22rem;
        max-width: 30rem;
        padding: 1rem 1.5rem;
    }

    .icons {
        font-size: 2.3rem;
    }

    .handsHelpingicon {
        font-size: 2.7rem;
    }

}


@media screen and (max-width:550px) {
    .AboutUs-conatainer {
        padding: 2rem 2rem 3rem 2rem;
    }

    .sections {
        min-width: 18rem;
        max-width: 18rem;
        padding: 1rem 1.5rem;
    }

    .Aboutus_heading h2 {
        font-size: 1.7rem;
    }

    .AboutusSection_container {
        gap: 2rem;
        padding: 2rem 1.5rem;
    }

    .scrollContainer {
        width: 33px;
        height: 33px;
        font-size: 1rem;
        right: 2rem;
    }
}