.xhipTrack-conatainer {
  padding: 7rem 5rem 7rem 5rem !important;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: var(--bs-bgcolor);
  font-family: var(--bs-font-archivo);
}

.xhipTrack_heading h2 {
  font-size: 1.8rem;
  letter-spacing: 0.07rem;
  color: var(--bs-darkblue);
  font-weight: 600;
}

.section-title-line {
  display: inline-block;
  width: 13rem;
  height: 0.07rem;
  background-color: var(--bs-lightgray);
  position: relative;
}

.section-title-line::after {
  position: absolute;
  content: "";
  width: 3rem;
  height: 0.15rem;
  background-color: var(--bs-orange);
  inset: 0;
  left: 50%;
  top: 0.1rem;
  transform: translate(-50%, -50%);
}

.xhipTrackSection_container {
  display: flex;
  justify-content: space-evenly;
  gap: 2.5rem;
  padding: 0;
  flex-wrap: wrap;
}

.Tracksections {
  padding: 0rem !important;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100% !important;
}

.trackMilestone .milestones::-webkit-scrollbar {
  color: #091649;
  width: 3px;
  height: 3px;
}

.trackMilestone .milestones::-webkit-scrollbar-track {
  background: #f2f2fb;
  border-radius: 5px;
}

.trackMilestone .milestones::-webkit-scrollbar-thumb {
  background: #ff4f00;
  border-radius: 5px;
}
.Tracksections .inputContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.Tracksections .inputContainer input {
  width: 100%;
  height: 3rem !important;
  max-width: 400px !important;
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
  box-shadow: 0px 0px 20px -5px rgba(222, 222, 222, 0.436) !important;
  border: none !important;
  outline: none;
  font-size: 1rem;
  font-family: var(--bs-font-archivo);
}

.Tracksections .inputContainer button {
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
  height: 100%;
  border: none;
  outline: none;
  background-color: var(--bs-orange);
  color: var(--bs-white);
  font-size: 1rem;
  font-family: var(--bs-font-archivo);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.xhipTrack-conatainer .icon-text h4::after {
  position: absolute;
  content: "";
  width: 4rem;
  height: 0.15rem;
  background-color: var(--bs-lightgray);
  inset: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 33px;
}

.icon-text p {
  color: var(--bs-gray);
  font-size: 0.98rem;
}

.scrollContainer {
  position: fixed;
  bottom: 3rem;
  width: 40px;
  height: 40px;
  right: 3rem;
  background-color: rgb(0, 0, 0);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--bs-white);
  font-weight: bolder;
  font-size: 1.2rem;
  box-shadow: 0px 0px 7px var(--bs-darkblue);
  z-index: 8;
  animation: scroll 0.35s linear;
}

.milestonesContainer {
  position: relative;
  display: flex;
  flex-direction: row !important;
  gap: 1.3rem;
  padding: 1rem 0rem;
  background-color: var(--bs-white);
  width: 100% !important;
  border-radius: 0.4rem;
  box-shadow: 0px 0px 20px -5px rgba(222, 222, 222, 0.436);
  padding: 2.6rem 1rem 0rem !important;
}

.milestonesContainer label {
  font-size: 12px;
  color: #09164970;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  position: absolute;
  top: 10px;
  left: 10px;
}

@keyframes scroll {
  from {
    width: 0;
    height: 0;
    opacity: 0;
  }

  to {
    width: 40;
    height: 40;
    opacity: 1;
  }
}

.scrollContainer a {
  color: var(--bs-white);
}

@media screen and (max-width: 1452px) {
  .xhipTrack-conatainer {
    padding: 5rem 5rem 5rem 5rem;
  }

  .Tracksections {
    width: 17rem;
    padding: 1rem 1.3rem;
  }
}

@media screen and (max-width: 1186px) {
  .xhipTrackSection_container {
    flex-wrap: nowrap;
  }
}

@media screen and (max-width: 1080px) {
  .xhipTrack-conatainer {
    padding: 5rem 5rem 5rem 5rem;
  }

  .xhipTrackSection_container {
    flex-wrap: wrap;
  }

  /* .Tracksections {
        min-width: 20rem;
        max-width: 35rem;
        width: 35rem;
        padding: 1rem 1.5rem;
    } */

  .handsHelpingicon {
    font-size: 2.7rem;
  }
}

@media screen and (max-width: 765px) {
  .xhipTrack-conatainer {
    padding: 20px 10px !important;
    gap: 1rem;
  }

  .xhipTrack_heading h2 {
    font-size: 1.98rem;
  }

  .xhipTrackSection_container {
    gap: 2rem;
    padding: 3rem 1.5rem;
  }

  .Tracksections {
    flex-direction: column !important;
    padding: 1rem 1.5rem;
  }

  .Tracksections .inputContainer {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    justify-content: center;
  }

  .Tracksections .inputContainer input {
    width: 100%;
    height: 4rem !important;
    max-width: 400px !important;
    padding: 0.5rem 1rem;
    border-radius: 0.4rem;
    box-shadow: 0px 0px 20px -5px rgba(222, 222, 222, 0.436) !important;
    border: none !important;
    outline: none;
    font-size: 1rem;
    font-family: var(--bs-font-archivo);
  }

  .Tracksections .inputContainer button {
    padding: 0.5rem 0.5rem;
    border-radius: 0.4rem;
    height: 40px;
    border: none;
    outline: none;
    background-color: var(--bs-orange);
    color: var(--bs-white);
    font-size: 1rem;
    font-family: var(--bs-font-archivo);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
  }

  .icons {
    font-size: 2.3rem;
  }

  .handsHelpingicon {
    font-size: 2.7rem;
  }
}

@media screen and (max-width: 550px) {
  .xhipTrack-conatainer {
    padding: 2rem 2rem 3rem 2rem;
  }

  .Tracksections {
    padding: 1rem 1.5rem;
  }

  .xhipTrack_heading h2 {
    font-size: 1.7rem;
  }

  .xhipTrackSection_container {
    gap: 2rem;
    padding: 2rem 1.5rem;
  }

  .scrollContainer {
    width: 33px;
    height: 33px;
    font-size: 1rem;
    right: 2rem;
  }
}

.trackMilestone {
  border-radius: 5px !important;
  border: 1px solid #dddded;
  display: flex;
  flex-direction: column;
  padding: 0.7rem 1.3rem;
  gap: 0.7rem;
  /* max-width: 400px !important; */
  /* height: 100% !important; */
  flex-grow: 1 !important;
  font-family: var(--bs-font-archivo);
}

.trackMilestone header span {
  color: #a6abbe;
  font-size: 0.84rem;
}

.trackMilestone .milestones {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  flex-wrap: nowrap;
  height: max-content !important;
  width: fit-content !important;
  overflow-x: scroll !important;
  padding-bottom: 1.5rem;
  font-family: var(--bs-font-archivo);
}

.trackMilestone .milestones .mile {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  position: relative;
}

.trackMilestone .milestones .mile .checkIcon {
  padding: 0.5rem 0.7rem !important;
  background-color: #f2f2fb;
  border-radius: 5px !important;
  border: 1px Solid #f2f2fb;
  font-family: var(--bs-font-archivo);
}

.trackMilestone .milestones .mile .checkIcon.inactive {
  background-color: #fff;
  border: 1px Solid #091649;
}

.trackMilestone .milestones .mile .milestoneName {
  display: flex;
  flex-direction: column;
  font-size: 0.88rem;
  width: max-content;
}

.trackMilestone .milestones .mile .milestoneName p {
  color: #091649;
  font-size: 0.88rem;
  font-family: var(--bs- FontMedium);
  text-transform: capitalize;
}

.trackMilestone .milestones .mile .milestoneName span {
  color: #616a8a;
  font-size: 0.73rem;
  text-align: left;
  font-family: var(--bs-font-archivo);
}

.milestoneError {
  font-size: 13px;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  color: #ff0000;
}

.trackMilestone .milestones .mile {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  position: relative;
}

.trackMilestone .milestones .mile .checkIcon {
  padding: 0.5rem 0.7rem !important;
  background-color: #f2f2fb;
  border-radius: 5px !important;
  border: 1px Solid #f2f2fb;
}

.trackMilestone .milestones .mile .checkIcon.inactive {
  opacity: 0.4 !important;
  background-color: #fff;
  border: 1px Solid #091649;
}

.trackMilestone .milestones .mile .milestoneName {
  display: flex;
  flex-direction: column;
  font-size: 0.88rem;
}

.trackMilestone .milestones .mile .milestoneName p {
  color: #091649;
  font-size: 0.88rem;
  font-family: var(--bs-mainFontMedium);
  text-transform: capitalize;
}

.trackMilestone .milestones .mile .milestoneName span {
  color: #616a8a;
  font-size: 0.73rem;
}

.trackMilestone .milestones .mile.disabled {
  opacity: 0.3 !important;
}

.xhipTrack_footer {
  margin-top: 2rem;
  font-size: 12px;
  font-weight: 600;
  color: var(--bs-orange);
  text-decoration: underline;
}